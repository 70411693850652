.molecule-navitem {
    cursor: pointer !important;
    // padding: 0px 16px;
    &__text {
      color: #808080 !important;
      transition: 0.3s;
      &:hover {
        color: #ffffff !important;
      }
    }

    a {
      text-decoration: none;
      color: #DDD !important;
      font-size: 14px;
    }
  
    &-active {
      &__text {
        color: #ffffff !important;
      }
    }
  }
  