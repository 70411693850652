.organism-footer {
  display: block;
  width: 100%;
  background-color: black;
  z-index: 1000;

  &__connection-list {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    &__details {
      @media only screen and (max-width: 464px) {
        flex-direction: column;
      }
    }
  }
}
