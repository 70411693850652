.atom-icon {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
  object-fit: contain;
  user-select: none;
  
  &-small {
    width: 16px;
    height: 16px;
  }

  &-uppersmall {
    width: 20px;
    height: 20px;
  }

  &-medium {
    width: 24px;
    height: 24px;
  }

  &-uppermedium {
    width: 28px;
    height: 28px;
  }
  
  &-large {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
  }

  &-upperlarge {
    width: 132px;
    height: 48px;
  }

  &-superlarge {
    width: 178px;
    height: 48px;
  }

  &-extrasuperlarge {
    width: 240px;
    height: 96px;
  }
}
