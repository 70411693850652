.atom-container {
  margin: 0 auto;
  width: calc(100% - 40px);
  padding-left: 70px;
  padding-right: 70px;
  background-color: transparent;
  // min-width: 320px;
  @media only screen and (min-width: 464px) {
    max-width: calc(100% - 140px);
  }
  @media only screen and (max-width: 464px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  // @media only screen and (min-width: 900px) {
  //   max-width: 860px;
  // }
  // @media only screen and (min-width: 1200px) {
  //   max-width: 1150px;
  // }
  // @media only screen and (min-width: 1500px) {
  //   max-width: 1440px;
  // }
  // @media only screen and (min-width: 1620px) {
  //   max-width: 1540px;
  // }
  // @media only screen and (min-width: 1800px) {
  //   max-width: 1720px;
  // }
  // @media only screen and (min-width: 2400px) {
  //   max-width: 2340px;
  // }
  // @media only screen and (min-width: 2560px) {
  //   max-width: 2500px;
  // }
  // @media only screen and (min-width: 3000px) {
  //   max-width: 2960px;
  // }
  // @media only screen and (min-width: 3200px) {
  //   max-width: 3160px;
  // }
}
