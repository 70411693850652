.molecule-button {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 20px 20px 20px 20px;
  transition: 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  &-disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
}
