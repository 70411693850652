.atom-text {
  transition: 0.4s;
  color: black;

  &-bigcount {
    font-size: 150px;
  }
  
  &-logo {
    font-size: 64px;
  }

  &-sublogo {
    font-size: 50px;
  }

  &-title {
    font-size: 39px;
  }

  &-undertitle {
    font-size: 33px;
  }

  &-subheadtitle {
    font-size: 31px;
  }

  &-smalltitle {
    font-size: 27px;
  }

  &-uppersubtitle {
    font-size: 25px;
  }

  &-subtitle {
    font-size: 21px;
  }

  &-undersubtitle {
    font-size: 18px;
  }

  &-header {
    font-size: 16px;
    line-height: 20px;
  }

  &-paragraph {
    font-size: 15px;
    line-height: 20px;
  }

  &-plain {
    font-size: 14px;
  }

  &-tiny {
    font-size: 11px;
  }

  &-button {
    font-size: 17px;
    font-weight: 600;
  }

  //////////////  Mobile View  ////////////////
  &-mobilebigcount {
    font-size: 150px;
  }

  &-mobilelogo {
    font-size: 48px;
  }

  &-mobilesublogo {
    font-size: 32px;
  }

  &-mobiletitle {
    font-size: 32px;
  }

  &-mobileundertitle {
    font-size: 30px;
  }

  &-mobilesubheadtitle {
    font-size: 22px;
  }

  &-mobilesmalltitle {
    font-size: 21px;
  }

  &-mobileuppersubtitle {
    font-size: 21px;
  }

  &-mobilesubtitle {
    font-size: 19px;
  }

  &-mobileundersubtitle {
    font-size: 17px;
  }

  &-mobileheader {
    font-size: 16px;
    line-height: 20px;
  }

  &-mobileparagraph {
    font-size: 15px;
    line-height: 20px;
  }

  &-mobileplain {
    font-size: 14px;
  }

  &-mobiletiny {
    font-size: 11px;
  }

  &-mobilebutton {
    font-size: 14px;
    font-weight: 600;
  }
}
