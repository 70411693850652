.atom-box {
  transition: 0.4s;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: black;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #5d6163;
    // border-radius: 20px;
    border: 0px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  &-cursor {
    cursor: pointer !important;
  }

  &-backdrop {
    -webkit-backdrop-filter: blur(10px);
  }

  &-hoverColor {
    &-grey {
      transition: 0.4s;
      &:hover {
        // background-color: #171717 !important;
      }
    }
  }

  &-hoverBorderColor {
    &-blue {
      transition: 0.4s !important;
      box-sizing: border-box !important;
      cursor: pointer !important;
      border-width: 2px !important;
      border-color: transparent !important;
      &:hover {
        border-width: 2px !important;
        border-color: #1b00ff !important;
      }
    }
    &-teal {
      transition: 0.4s !important;
      box-sizing: border-box !important;
      cursor: pointer !important;
      border-width: 2px !important;
      border-color: transparent !important;
      &:hover {
        border-width: 2px !important;
        border-color: #3DC2CD !important;
      }
    }
    &-purple {
      transition: 0.4s !important;
      box-sizing: border-box !important;
      cursor: pointer !important;
      border-width: 2px !important;
      border-color: transparent !important;
      &:hover {
        border-width: 2px !important;
        border-color: #7F8DE3 !important;
      }
    }
    &-green {
      transition: 0.4s !important;
      box-sizing: border-box !important;
      cursor: pointer !important;
      border-width: 2px !important;
      border-color: transparent !important;
      &:hover {
        border-width: 2px !important;
        border-color: #b1de04 !important;
      }
    }
  }
}
