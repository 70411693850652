body {
  margin: 0;
}

@font-face {
  font-family: "RextonRegular";
  src: local("RextonRegular"),
   url("./fonts/Rexton_Regular.otf") format("truetype");
  font-weight: bold;
  }

  @font-face {
    font-family: "RextonMedium";
    src: local("RextonMedium"),
     url("./fonts/Rexton_Medium.otf") format("truetype");
    font-weight: bold;
    }

    @font-face {
      font-family: "RextonBold";
      src: local("RextonBold"),
       url("./fonts/Rexton_Bold.otf") format("truetype");
      font-weight: bold;
      }

  @font-face {
    font-family: "GilroyRegular";
    src: local("GilroyRegular"),
     url("./fonts/Gilroy-Regular.ttf") format("truetype");
    font-weight: bold;
    }

    @font-face {
      font-family: "GilroyBold";
      src: local("GilroyBold"),
       url("./fonts/Gilroy-Bold.ttf") format("truetype");
      font-weight: bold;
      }

      @font-face {
        font-family: "GilroySemiBold";
        src: local("GilroySemiBold"),
         url("./fonts/Gilroy-SemiBold.ttf") format("truetype");
        font-weight: bold;
        }

* {
  font-family: "GilroyRegular", Sans-serif;

  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ccc;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5d6163;
    border: 0px solid transparent;
    background-clip: content-box;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}